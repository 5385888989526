import React from 'react';
import { FloatButton } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';


const ExportToCSV = ({ headers, reportToCSVData }) => {
    const handleExportCSV = () => {
        const csvContent = `\uFEFF${createCSVContent(headers, reportToCSVData)}`;
        const filename = 'report-sheet.csv';
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, filename);
    };

    const createCSVContent = (headers, data) => {
        const headerRow = headers.map((header) => header.label || header.key).join(',');

        // Modify the dataRows creation to handle arrays
        const dataRows = data.map((row) => {
          return headers.map((header) => {
            // Check if the current header corresponds to an array field
         
            if (Array.isArray(row[header.key])) {
              // If it's an array, join its values with commas
              return row[header.key].join('|');
            } else {
              // If it's not an array, use the value directly
              return row[header.key];
            }
          }).join(',');
        }).join('\n');
      
        return `${headerRow}\n${dataRows}`;









    };

    const isDataAvailable = reportToCSVData?.length < 1;

    return (
        <FloatButton
            tooltip='Export to CSV'
            type='primary'
            onClick={handleExportCSV}
            icon={<ExportOutlined />}
            className={`${isDataAvailable ? 'collapse' : ''}`}
        ></FloatButton>
    );
};

export default ExportToCSV;
