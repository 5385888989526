import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import {
  useCreateProductMutation
} from '../slices/productsApiSlice';


import BarcodeScanner from '../components/BarcodeScanner';
import { FaTruckMonster } from 'react-icons/fa';


const NewProductScreen = () => {

  const [status] = useState('New');//new
  const [uniqueNumber, setUniqueNumber] = useState('');
  const formRef = useRef(null);
  const [isCameraVisible, setCameraVisible] = useState(FaTruckMonster);
  const [scannedText, setScannedText] = useState('');

  const [createProduct, { isLoading: loadingCreate }] =
    useCreateProductMutation();



  const displayCamera = () => {
    // Toggle the visibility of the code
    setCameraVisible(!isCameraVisible);
  };

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();


    
    try {
      await createProduct({
        status, uniqueNumber
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Product created');

      navigate('/productlist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
    finally {
      // Reset the scannedText after submission
      setScannedText('');
    }


  };

  const handleBarcodeScan = (scannedText) => {
    setUniqueNumber(scannedText);
    setCameraVisible(false);
    setScannedText(scannedText)
      ;
  };

  useEffect(() => {
    // Check if the formRef and uniqueNumber are available
    if (formRef.current && uniqueNumber !== '' && scannedText !== '') {
  
      // Trigger form submission
      submitHandler({ preventDefault: () => { } }); // Pass a fake event object
    }
  }, [uniqueNumber, scannedText]);

  return (
    <>

      <Link to='/productlist' className='btn btn-light my-3'> Go Back </Link>
      <FormContainer>
        <h1>Add New Product</h1>

        <Form ref={formRef} onSubmit={submitHandler}>

          <Form.Group controlId='uniqueNumber'>
            <Form.Label onClick={displayCamera}>Unique Number</Form.Label>

            {isCameraVisible ?

              <BarcodeScanner onBarcodeScan={handleBarcodeScan} /> : '    Click on Unique Number for scanner'}


            <Form.Control
              type='number'
              placeholder='Enter unique number'
              value={uniqueNumber}
              onChange={(e) => setUniqueNumber(e.target.value)}

            ></Form.Control>
          </Form.Group>

          <Button
            disabled={loadingCreate}
            type='submit'
            variant='primary'
            style={{ marginTop: '1rem' }}
          >
            Add
          </Button>

        </Form>

      </FormContainer >
    </>
  );
}

export default NewProductScreen;