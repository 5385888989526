import React, { useState, useEffect } from 'react';

import { Table, Button } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';
import { BiSort } from 'react-icons/bi';
import axiosInstance from '../utils/axiosInstance';
import { FaEdit } from 'react-icons/fa';
import Loader from '../components/Loader';
import Message from '../components/Message';
import CsvParser from '../components/CSVParser';



const BulkUpload = () => {



  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState('');

  const [sort, setSort] = useState({ keyToSort: 'default', direction: 'asc' });

  useEffect(() => {
    const savedData = localStorage.getItem('bulkUploadData');
    if (savedData) {
      setData(JSON.parse(savedData));
    }
  }, []);

  const getUniqueNumbersList = async (partsData) => {
    try {
      setLoading(true);
      setError('');
      localStorage.removeItem('bulkUploadData');

      if (partsData.length > 0) {
        await axiosInstance.put('/api/products/1/uniqueNumbers', {
          data: {

            partsData
          },

        }).then(response => {
          // Assuming your response object has a 'data' property
          setData(response.data);
          setLoading(false);
          localStorage.setItem('bulkUploadData', JSON.stringify(response.data));
        })
          .catch(error => {
            localStorage.removeItem('bulkUploadData');
            console.error('Error fetching data:', error);
          });

      }

    } catch (err) {
      localStorage.removeItem('bulkUploadData');

      setLoading(false);
      setData(null);
      setError(err.response?.data?.message || 'An error occurred');
    }
    finally {
      setLoading(false);
    }
  }

  const handleDataReceived = (data) => {

    getUniqueNumbersList(data);
    localStorage.setItem('bulkUploadData', JSON.stringify(data));
  };
  function getSortedArray(arrayToSort) {

    if (sort.keyToSort !== 'defsult') {
      if (sort.direction === 'asc') {
        return arrayToSort.sort((a, b) =>
          compareValues(getField(a, sort.keyToSort), getField(b, sort.keyToSort))
        );
      }
      return arrayToSort.sort((a, b) =>
        compareValues(getField(b, sort.keyToSort), getField(a, sort.keyToSort))
      );
    }

  }

  function getField(object, field) {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      // Check if the current field is defined in the current value
      if (value && typeof value === 'object' && f in value) {
        value = value[f];
      } else {
        // Handle the case where the field is undefined
        return '';
      }
    }
    return value;
  }

  function compareValues(valueA, valueB) {

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  }

  const headers = [{ label: 'Part Number', key: 'partNumber' }, { label: 'Unique No', key: 'uniqueNumber' },
  { label: 'Location', key: 'row' }, { label: 'Weight', key: 'weight' }, { label: 'Length', key: 'length' },
  { label: 'Width', key: 'width' }, { label: 'Height', key: 'height' }];
  function handleHeaderClick(header) {
    setSort({
      keyToSort: header,
      direction:
        header === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc'
          : 'desc',

    })

  }
  return (
    <>
      <CsvParser onParseComplete={handleDataReceived} />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <div>

          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} onClick={() => handleHeaderClick(header.key)} style={{ cursor: 'pointer' }}> {header.label}
                    {sort.keyToSort === header.key && (
                      <BiSort
                        style={{
                          marginLeft: '5px',
                          verticalAlign: sort.direction === 'asc' ? 'text-bottom' : 'text-top',
                          transform: sort.direction === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    )}

                  </th>
                ))}
                <th>Actions</th>
              </tr>

            </thead>
            <tbody>
              {data && data.products && getSortedArray(data.products).map((product, index) => (
                <tr key={product._id}>
                  <td> {product.partNumber}</td>
                  <td>{product.uniqueNumber}</td>
                  <td>{product.row} -{product.bay}-{product.bin}{product.section ? '-' + product.section : ""}</td>
                  <td> {product.weight}</td>
                  <td> {product.length}</td>
                  <td> {product.width}</td>
                  <td> {product.height}</td>
                  <td>
                    <LinkContainer to={`/product/${product._id}/edit/bulkUpload`}>
                      <Button variant='light' className='btn-sm mx-2'>
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                  </td>

                </tr>
              ))}
              {data && data.products && (
                <tr>
                  Total:  {data.products.length}
                </tr>

              )}

            </tbody>

          </Table>

        </div>
      )}
    </>
  );

}


export default BulkUpload;