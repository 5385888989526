import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider} from 'react-helmet-async';
import store from "./store";
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/admin/RegisterScreen";
import ProductListScreen from "./screens/ProductListScreen";

import ReportSearch from "./components/ReportSearch";

import BulkUpload from "./screens/BulkUploadReport";


import ProductListByStatusScreen from "./screens/ProductListByStatusScreen";

import ProductEditScreen from "./screens/ProductEditScreen";
import OldListedProductsScreen from "./screens/OldListedProductsScreen";
import NewProductScreen from "./screens/NewProductScreen";
import ProfileScreen from './screens/ProfileScreen';
import UserListScreen from './screens/admin/UserListScreen';
import RoleListScreen from './screens/admin/RoleListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import AdminRoute from "./components/AdminRoute";
import PrivateRoute from './components/PrivateRoute';
import AddRoleScreen from "./screens/admin/AddRole";
import RoleEditScreen from "./screens/admin/RoleEditScreen";


const router = createBrowserRouter(

  
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<LoginScreen />} />
      <Route path="/login" element={<LoginScreen />} /> 
          
        {/* Registered users */}
      <Route path='' element={<PrivateRoute />}>
         <Route path='/profile' element={<ProfileScreen />} />
         <Route path="/productlist" element={<ProductListScreen />} />
         <Route path="/page/:pageNumber" element={<ProductListScreen />} />
         <Route path="/productlist/search/:keyword" element={<ProductListScreen />} />
         <Route path="/search/:keyword/page/:pageNumber" element={<ProductListScreen />} />
         <Route path="/product/create" element={<NewProductScreen />} />
         <Route path="/product/:id/edit/:from" element={<ProductEditScreen />} />
      
      </Route>

      <Route path="" element={<AdminRoute />} />
          <Route path="/admin/userlist" element={<UserListScreen />} />
          <Route path="/admin/rolelist" element={<RoleListScreen />} />
          <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
          <Route path="/admin/user/register" element={<RegisterScreen />} />
          <Route path="/admin/role/addRole" element={<AddRoleScreen />} />
          <Route path="/admin/role/:id/edit" element={<RoleEditScreen />} />
        
          <Route path="/getStatus" element={<ProductListByStatusScreen status ='Listed' />} />
          <Route path="/getStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='Listed'/>} />
          <Route path="/search/:status/:keyword" element={<ProductListByStatusScreen status ='Listed'/>} />
          <Route path="search/:keyword/getStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='Listed' />} />

          <Route path="/searchStatus/:status/:keyword" element={<ProductListByStatusScreen status ='Sold'/>} />
          <Route path="/getSoldStatus" element={<ProductListByStatusScreen status ='Sold' />} />
          <Route path="/getSoldStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='Sold'/>} /> 
          <Route path="search/:keyword/getSoldStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='Sold' />} />

          <Route path="/cheapItemProducts/:keyword" element={<ProductListByStatusScreen status ='CheapItem'/>} />
          <Route path="/cheapItemProducts" element={<ProductListByStatusScreen status ='CheapItem' />} />
          <Route path="/getCheapItemStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='CheapItem'/>} /> 
          <Route path="search/:keyword/getCheapItemStatus/:status/page/:pageNumber" element={<ProductListByStatusScreen status ='CheapItem' />} />


          <Route path="/idleProducts" element={<OldListedProductsScreen  />} />
          <Route path="/idleProducts/:keyword" element={<OldListedProductsScreen  />} />
          <Route path="/idleProducts/page/:pageNumber" element={<OldListedProductsScreen  />} />
          <Route path="/idleProducts/:keyword/page/:pageNumber" element={<OldListedProductsScreen  />} />
     
          
          <Route path="/report" element={<ReportSearch />} />
          <Route path="/bulkUploadReport" element={<BulkUpload />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
    </HelmetProvider>
   
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
