import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  useGetRoleDetailsQuery,
  useUpdateRoleMutation,
} from '../../slices/rolesApiSlice';
import { permissionsList, roleStatusOptions } from '../../constants';
import { Select, Space } from 'antd';


const RoleEditScreen = () => {
  const { id: roleId } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [productStatusesToDisplay, setProductStatusesToDisplay] = useState([]);


  const {
    data: role,
    isLoading,
    error,
    refetch,
  } = useGetRoleDetailsQuery(roleId);

  const [updateRole, { isLoading: loadingUpdate }] = useUpdateRoleMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (permissions.length === 0) {
      toast.error('Please select at least one permission! ');
      return;
    }

    if (productStatusesToDisplay.length === 0) {
      toast.error('Please select at least one status! ');
      return;
    }
    try {
      await updateRole({ roleId, name, description, isActive, permissions, productStatusesToDisplay }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block;
      toast.success('role updated successfully');
      refetch({ force: true });
      navigate('/admin/rolelist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (role) {
      setName(role.name);
      setDescription(role.description);
      setIsActive(role.isActive);
      setPermissions(role.permissions);
      setProductStatusesToDisplay(role.productStatusesToDisplay);
    }
  }, [role]);

  return (
    <>
      <Link to='/admin/rolelist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Role</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>
            {error?.data?.message || error.error}
          </Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group className='my-2' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                readOnly
              ></Form.Control>
            </Form.Group>

            <Form.Group className='my-2' controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type='description'
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>




            <Form.Group controlId='permissions' style={{ marginBottom: '20px' }} >
              <Form.Label>Permissions </Form.Label>
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                  height: 'auto',
                }}

                placeholder="Select Permissions"
                value={permissions}

                onChange={(selectedValues) => setPermissions(selectedValues)}
                optionLabelProp="label"
                options={permissionsList}
                optionRender={(option) => (
                  <Space>
                    <span role="img" aria-label={option.data.value}>
                      {option.data.label}
                    </span>

                  </Space>
                )}

              />
            </Form.Group>

            <Form.Group controlId='productStatusesToDisplay' style={{ marginBottom: '20px' }} >
              <Form.Label> Select Status To Display In Product List </Form.Label>
              <Select
                mode="multiple"
                style={{
                  width: '100%',
                  height: 'auto',
                }}

                placeholder="Status To Display In Product List"
                value={productStatusesToDisplay}
                required
                onChange={(selectedValues) => setProductStatusesToDisplay(selectedValues)}
                optionLabelProp="label"
                options={roleStatusOptions}
                optionRender={(option) => (
                  <Space>
                    <span role="img" aria-label={option.data.label}>
                      {option.data.value}
                    </span>

                  </Space>
                )}

              />
            </Form.Group>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default RoleEditScreen;