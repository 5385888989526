// FreightOptions.js
import React from 'react';
import { Form } from 'react-bootstrap';
import { freightOptions } from '../constants';





const FreightOptions = ({ freightOption, onFreightOptionChange, canEditField }) => {
  return (
    <Form.Group controlId='freight'>
      <Form.Label>Freight</Form.Label>
      <Form.Control
        as='select'
        placeholder='Enter freight'
        value={freightOption}
        onChange={(e) => onFreightOptionChange(e.target.value)}
        disabled={!canEditField}
      >
        {freightOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default FreightOptions;
