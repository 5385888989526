import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { MdBarcodeReader } from "react-icons/md";
import BarcodeScanner from '../components/BarcodeScanner';



const SearchBox = ({ from = 'list' }) => {
  const navigate = useNavigate();
  const { keyword: urlKeyword } = useParams();

  const [isCameraVisible, setCameraVisible] = useState(false);
  const [scannedText, setScannedText] = useState('');


  // FIX: uncontrolled input - urlKeyword may be undefined
  const [keyword, setKeyword] = useState(urlKeyword || '');



  const submitHandler = (e) => {
    e.preventDefault();
console.log('from',from);
    if (from === 'list') {

      if (keyword) {
        navigate(`/productlist/search/${keyword.trim()}`);
        setKeyword('');
      } else {
        navigate('/productlist');
      }
    } else if (from === 'IdleProduct') {

      if (keyword) {
        navigate(`/idleProducts/${keyword.trim()}`);
        setKeyword('');
      } else {
        navigate('/idleProducts');
      }
    }else if (from === 'CheapItem') {

      if (keyword) {
        navigate(`/cheapItemProducts/${keyword.trim()}`);
        setKeyword('');
      } else {
        navigate('/cheapItemProducts');
      }
    }

    else {// from other dashboard like listed items

      if (keyword) {
        if (from === 'Listed' ){
          
          navigate(`/search/${from}/${keyword.trim()}`);
        }
         
        else {
          navigate(`/searchStatus/${from}/${keyword.trim()}`);
        }
        setKeyword('');
      } else {
        navigate('/getStatus');
      }

    }

  };

  const displayCamera = () => {
    // Toggle the visibility of the code
    setCameraVisible(!isCameraVisible);
  };


  const handleBarcodeScan = (scannedText) => {
    setKeyword(scannedText);
    setScannedText(scannedText);
    setCameraVisible(false);

  };

  useEffect(() => {
    // Check if the formRef and uniqueNumber are available

    if (scannedText !== '') {

      if (keyword) {

        navigate(`/productlist/search/${keyword.trim()}`);
        //setKeyword('');
      } else {

        navigate('/productlist');
      }
      // Trigger form submission
    }
  }, [keyword, scannedText, navigate]);



  return (
    <Form onSubmit={submitHandler} className='d-flex flex-column'>
      <div className="d-flex">
        <Form.Control
          type='text'
          name='q'
          onChange={(e) => setKeyword(e.target.value.replace('/', ''))}
          value={keyword}
          placeholder='Search Products...'
          className='mr-sm-2 ml-sm-5'
        ></Form.Control>
        <Button type='submit' className='p-2 mx-2'>
          Search
        </Button>

        <Button onClick={displayCamera} className={`p-2 mx-2 ${isCameraVisible ? 'active' : ''}`}>
          <MdBarcodeReader />
        </Button>
      </div><br></br>

      {isCameraVisible && <BarcodeScanner onBarcodeScan={handleBarcodeScan} />}
    </Form>
  );
};

export default SearchBox;