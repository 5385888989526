import React, { useState } from 'react';
import Loader from '../components/Loader';

import { Form, Table, Button, Row, Col } from 'react-bootstrap';
import {
  useGetUsersQuery,
} from '../slices/usersApiSlice';
import Message from '../components/Message';
import { BiSort } from 'react-icons/bi';
import axiosInstance from '../utils/axiosInstance';
import { convertToTimeZone } from '../utils/dateToTimeZone';
import { TIME_ZONE } from '../constants';

import ExportToCSV from './ExportToCSV';
import { statusOptions } from '../constants';

const ReportSearch = () => {

  //const  { page, fromDate, toDate, selectedUsers, selectedStatus } = useParams();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateType, setDateType] = useState('');
  
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedListers, setSelectedListers] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [error1, setError] = useState('');
  const [data, setData] = useState('');

  const { data: users, error } = useGetUsersQuery();


  const [reportToCSVData, setReportToCSVData] = useState(null);



  const [sort, setSort] = useState({ keyToSort: 'partNummer', direction: 'asc' });


  function handleHeaderClick(header) {
    setSort({
      keyToSort: header,
      direction:
        header === sort.keyToSort ? sort.direction === 'asc' ? 'desc' : 'asc'
          : 'desc',

    })

  }


  function getSortedArray(arrayToSort) {

    if (sort.direction === 'asc') {
      return arrayToSort.sort((a, b) =>
        compareValues(getField(a, sort.keyToSort), getField(b, sort.keyToSort))
      );
    }
    return arrayToSort.sort((a, b) =>
      compareValues(getField(b, sort.keyToSort), getField(a, sort.keyToSort))
    );
  }

  function getField(object, field) {
    const fields = field.split('.');
    let value = object;

    for (const f of fields) {
      // Check if the current field is defined in the current value
      if (value && typeof value === 'object' && f in value) {
        value = value[f];
      } else {
        // Handle the case where the field is undefined
        return '';
      }
    }
    return value;
  }

  function compareValues(valueA, valueB) {
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.localeCompare(valueB);
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  }





  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axiosInstance.get('/api/reports/productsReport', {
        params: {
          fromDate,
          toDate,
          selectedUsers,
          selectedStatus,
          selectedListers,
          dateType

        },
      });
      setData(data);
      setReportToCSVData(data.products);
    } catch (err) {

      setLoading(false);
      setData(null);
      setError(err.response?.data?.message || 'An error occurred');
    }
    finally {
      setLoading(false);
    }
  };
  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    // Add validation to ensure fromDate is always less than toDate
    if (newFromDate < toDate || !toDate) {
      setFromDate(newFromDate);
    } else {
      // Handle invalid selection (optional)
      alert('Invalid date selection. Please choose a date before the ' + toDate + ' date.');
    }
  };

  const handleToDateChange = (e) => {
    const newToDate = e.target.value;

    if (newToDate > fromDate || !fromDate) {
      setToDate(newToDate);
    } else {
      // Handle invalid selection (optional)
      alert('Invalid date selection. Please choose a date after the ' + fromDate + 'date.');
    }
  };
  const handleSelectUserChange = (e) => {
    if (selectedListers && selectedListers.length > 0) {
      alert('Please select owner or lister. Can not select both');
    } else {
      const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
      setSelectedUsers(selectedValues);
    }
  };


  const handleSelectListerChange = (e) => {
    
    if (selectedUsers && selectedUsers.length > 0) {
      alert('Please select owne or lister. Can not select both');
    } else if (dateType && dateType !== 'listedDate') {
      alert('You must select listed date in the date type field');
    }
    else {
      const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);
      setSelectedListers(selectedValues);
    }
  }

  const handleStatusChange = (e) => {

    // Add validation logic here if needed
    // For example, ensure that the selected status is valid
    const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value);

    setSelectedStatus(selectedValues);
  };

  const headers = [{ label: 'Part Number', key: 'partNumber' }, { label: 'Unique No', key: 'uniqueNumber' },
  { label: 'Status', key: 'status' }, { label: 'Owner', key: 'user.name' }, { label: 'Creation Date', key: 'createdAt' },
  { label: 'Modification Date', key: 'updatedAt' },
  { label: 'Lister', key: 'lister.name' },
  { label: 'Listed Date', key: 'listerDate' }];

  const reportHeaders = [{ label: 'Part Number', key: 'partNumber' }, { label: 'Unique No', key: 'uniqueNumber' },
  { label: 'Condition', key: 'condition' }, { label: 'Condition Reason', key: 'conditionReason' }, { label: 'Quantity', key: 'quantity' }, { label: 'Row', key: 'row' },
  { label: 'Bay', key: 'bay' }, { label: 'Bin', key: 'bin' }, { label: 'Section', key: 'section' },
  { label: 'Freight', key: 'isFreightOn' }, { label: 'Freight option', key: 'freightOption' },
  { label: 'FreeShipping', key: 'isFreeShippingOn' }, { label: 'PaidShipping', key: 'isPaidShippingOn' },
  { label: 'Weight', key: 'weight' }, { label: 'Length', key: 'length' }, { label: 'Width', key: 'width' },
  { label: 'Height', key: 'height' }, { label: 'Images', key: 'images' }, { label: 'Comment', key: 'comment' }, { label: 'Public Images', key: 'publicImages' }
  ];
  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col>
            <Form.Label>From Date</Form.Label>
            <Form.Control type='date'

              value={fromDate} // Ensure it's in "yyyy-mm-dd" format
              onChange={handleFromDateChange}
              required
            ></Form.Control>
          </Col>
          <Col>
            <Form.Label>To Date</Form.Label>
            <Form.Control type='date'
              value={toDate}
              onChange={handleToDateChange}
              required
            ></Form.Control>
          </Col>
          <Col>
            <Form.Label>Date Type</Form.Label>
            <Form.Select
            required
              value={dateType}
              onChange={(e) => setDateType(e.target.value)}
              className="w-auto">
                <option  value=''> Select Date Type</option>
              <option  value='modificationDate'> Modification Date</option>
              <option  value='creationDate'>Creation Date </option>
              <option  value='listedDate'>Listed Date </option>

            </Form.Select>

            
          </Col>
          <Col>
            <Form.Label>Status</Form.Label>
            <Form.Select
              multiple
              value={selectedStatus}
              onChange={handleStatusChange}
              className="w-auto">

              {statusOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Owner</Form.Label>
            <Form.Select
              multiple
              value={selectedUsers}
              onChange={handleSelectUserChange}
              className="w-auto">

              {users && users.map((user, index) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Lister</Form.Label>
            <Form.Select
              multiple
              value={selectedListers}
              onChange={handleSelectListerChange}
              className="w-auto">

              {users && users.map((user, index) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Button
              type='submit'
              variant='primary'
              style={{ marginTop: 35 }}
            >
              Search
            </Button>
          </Col>
        </Row>
      </Form >
      {isLoading ? (
        <Loader />
      ) : error1 ? (
        <Message variant='danger'>{error?.data?.message}</Message>
      ) : ((data && data.products && data.products.length > 0) ?

        <><Row className='align-items-center'>
          <Col>
            <h2>Search Results {data.count} records</h2>
          </Col>
        </Row>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} onClick={() => handleHeaderClick(header.key)} style={{ cursor: 'pointer' }}> {header.label}
                    {sort.keyToSort === header.key && (
                      <BiSort
                        style={{
                          marginLeft: '5px',
                          verticalAlign: sort.direction === 'asc' ? 'text-bottom' : 'text-top',
                          transform: sort.direction === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    )}
                  </th>
                ))}
              </tr>

            </thead>
            <tbody>
              {data && data.products && getSortedArray(data.products).map((product, index) => (
                <tr key={product._id}>
                  <td> {product.partNumber}</td>
                  <td>{product.uniqueNumber}</td>
                  <td nowrap='true' style={{ backgroundColor: product.status === 'InProgress' ? 'orange' : 'inherit' }}>{product.status === 'UnlistedWProblems' ? 'Unlisted W/ Problems' : product.status === 'ListedWProblems' ? 'Listed W/ Problems' : product.status}</td>
                  <td>{product.user.name}</td>
                  <td>{convertToTimeZone(product.createdAt, TIME_ZONE)}</td>
                  <td>{convertToTimeZone(product.updatedAt, TIME_ZONE)}</td>

                  <td>{product.lister && product.lister.name ? product.lister.name : 'N/A'}</td>
                  <td>{product.listerDate ? convertToTimeZone(product.listerDate, TIME_ZONE) : 'N/A'}</td>

                </tr>
              ))}
            </tbody>

          </Table>
          {reportToCSVData && (
            <ExportToCSV
              headers={reportHeaders}
              reportToCSVData={reportToCSVData}
              isActive={reportToCSVData !== null}

            />
          )}
        </>
        :

        <Row className='align-items-center'>
          <Col>
            <h5>No records were found...</h5>
          </Col>
        </Row>
      )
      }
    </>
  )

}
export default ReportSearch;