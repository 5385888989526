import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

import Loader from '../../components/Loader';
import FormContainer from '../../components/FormContainer';

import { useAddRoleMutation } from '../../slices/rolesApiSlice';

import { toast } from 'react-toastify';
import { permissionsList, roleStatusOptions } from '../../constants';
import { Select, Space } from 'antd';


const AddRoleScreen = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [productStatusesToDisplay, setProductStatusesToDisplay] = useState([]);

  const navigate = useNavigate();

  const [addRole, { isLoading }] = useAddRoleMutation();
 
  const submitHandler = async (e) => {
    e.preventDefault();

    if(permissions.length===0){
    
      toast.error('Please select at least one permission! ');
      return;
    }
   
    if(productStatusesToDisplay.length===0){
      toast.error('Please select at least one status! ');
      return;
    }
  

    try {
      await addRole({ name, description, permissions,productStatusesToDisplay }).unwrap();
      // dispatch(setCredentials({ ...res }));
      // navigate(redirect);

      toast.success('Role created succesfully');

      navigate('/admin/rolelist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }

  };

  return (
    <FormContainer>
      <h1>Add Role</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className='my-2' controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className='my-2' controlId='description'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='description'
            placeholder='Enter description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='permissions' style={{ marginBottom: '20px' }} >
          <Form.Label>Permissions </Form.Label>
          <Select
            mode="multiple"
            style={{
              width: '100%',
              height: 'auto',
            }}

            placeholder="Select Permissions"
            value={permissions}
            aria-required = "true"
            onChange={(selectedValues) => setPermissions(selectedValues)}
            optionLabelProp="label"
            options={permissionsList}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.label}>
                  {option.data.value}
                </span>

              </Space>
            )}

          />
             
        </Form.Group>
        <Form.Group controlId='productStatusesToDisplay' style={{ marginBottom: '20px' }} >
          <Form.Label> Select Status To Display In Product List </Form.Label>
          <Select
            mode="multiple"
            style={{
              width: '100%',
              height: 'auto',
            }}

            placeholder="Status To Display In Product List"
            value={productStatusesToDisplay}
   
            onChange={(selectedValues) => setProductStatusesToDisplay(selectedValues)}
            optionLabelProp="label"
            options={roleStatusOptions}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.label}>
                  {option.data.value}
                </span>

              </Space>
            )}

          />
          
        </Form.Group>


        <Button disabled={isLoading} type='submit' variant='primary'>
          Add Role
        </Button>

        {isLoading && <Loader />}
      </Form>


    </FormContainer>
  );
};

export default AddRoleScreen;