import { PRODUCTS_URL, UPLOAD_URL, DISPLAY_URL , REPORTS_URL} from "../constants";
import { apiSlice } from "./appSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: PRODUCTS_URL,
        params: {
          pageNumber,
          keyword,
        },
      }),
      invalidatesTags: ["Product"],
      keepUnusedDataFor: 0,
      providesTags: ["Products"],
    }),
    getProductById: builder.query({
      query: (productId) => ({
        url: PRODUCTS_URL + "/" + productId,
      }),
      keepUnusedDataFor: 5,
    }),

    getProductByStatus: builder.query({
      query: ({ pageNumber, status, keyword}) => ({
        url: PRODUCTS_URL + "/" + status + "/getStatus",
        params: {
          pageNumber,
         status,
         keyword
        },
      }),
      invalidatesTags: ["Product"],
      keepUnusedDataFor: 5,
      providesTags: ["Products"],
    }),
    


    getListedOldProducts: builder.query({
      query: ({ pageNumber, status, keyword}) => ({
        url: REPORTS_URL +"/listedOldProducts",
        params: {
          pageNumber,
         status,
         keyword
        },
      }),
      invalidatesTags: ["Product"],
      keepUnusedDataFor: 5,
      providesTags: ["Products"],
    }),
    getProductDetails: builder.query({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
      }),
      keepUnusedDataFor: 5,
    }),

    
    productByUniqueNumbers: builder.mutation({
      query: (data) => ({
        url: PRODUCTS_URL + "/1/uniqueNumbers",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Product"], // stop to be cached and reload data
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCTS_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Product"],
    }),
    updateProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCTS_URL}/${data.productId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Products"], // stop to be cached and reload data
    }),
    uploadProductImage: builder.mutation({
      query: (data) => ({
        url: `${UPLOAD_URL}`,
        method: "POST",
        body: data,
        encType: "multipart/form-data",
      }),
      invalidatesTags: ["Products"], // stop to be cached and reload data
    }),

    displayProductImage: builder.mutation({
      query: (data) => ({
        url: `${DISPLAY_URL}/1`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Product"],
    }),

    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
        method: "DELETE",
      }),
      providesTags: ["Product"],
    }),

    assignToMeProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}/assignToMe`,
        method: "PUT",
      }),
      invalidatesTags: ["Products"], // stop to be cached and reload data
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useGetProductDetailsQuery,
  useGetProductByStatusQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useUploadProductImageMutation,
  useDeleteProductMutation,
  useDisplayProductImageMutation,
  useAssignToMeProductMutation,
  useGetListedOldProductsQuery
} = productsApiSlice;
