// utils/productUtils.js
import {
  useDeleteProductMutation,
  useAssignToMeProductMutation,
} from "../slices/productsApiSlice";

export const useProductDeletion = () => {
  const [deleteProduct, { isLoading: loadingDelete }] =
    useDeleteProductMutation();

  const deleteHandler = async (id, refetchCallback, errorCallback) => {
    if (window.confirm("Are you sure?")) {
      try {
        await deleteProduct(id);
        refetchCallback();
      } catch (err) {
        errorCallback(err?.data?.message || err.error);
      }
    }
  };

  return { deleteHandler, loadingDelete };
};

export const useAssignToMe = () => {
  const [assignToMeProduct, { isLoading: assignToMeLoading }] =
    useAssignToMeProductMutation();

  const assignToMeHandler = async (id, refetchCallback, errorCallback) => {
    if (window.confirm("Are you sure?")) {
      try {
        const response = await assignToMeProduct(id);
        if (response?.error?.status === 400 && response?.error?.data?.message) {
          errorCallback(response?.error?.data.message);
        } else {
          refetchCallback();
        }
      } catch (err) {
        errorCallback(err?.data?.message || err.error);
      }
    }
  };
  return { assignToMeHandler, assignToMeLoading };
};

//export default { useProductDeletion, useAssignToMe };
