import { ROLES_URL } from "../constants";
import { apiSlice } from "./appSlice";


export const usersApiSlice = apiSlice.injectEndpoints({

    endpoints: (builder)=>({
      
        addRole: builder.mutation({
            query: (data) => ({
              url: `${ROLES_URL}`,
              method: 'POST',
              body: data,
            }),
          }),

      
     
          getRoles: builder.query({
            query: () => ({
              url: ROLES_URL,
            }),
            providesTags: ['Role'],
            keepUnusedDataFor: 5,
          }),
          deleteRole: builder.mutation({
            query: (roleId) => ({
              url: `${ROLES_URL}/${roleId}`,
              method: 'DELETE',
            }),
          }),
          getRoleDetails: builder.query({
            query: (id) => ({
              url: `${ROLES_URL}/${id}`,
            }),
            keepUnusedDataFor: 5,
          }),
          updateRole: builder.mutation({
            query: (data) => ({
              url: `${ROLES_URL}/${data.roleId}`,
              method: 'PUT',
              body: data,
            }),
            invalidatesTags: ['Role'],
          }),
        }),
        
   
});

export const { useAddRoleMutation,useDeleteRoleMutation,useUpdateRoleMutation,useGetRolesQuery,useGetRoleDetailsQuery} = usersApiSlice;