import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const generateLinkPath = (origin, keyword, page, status) => {
  if (origin === 'productList') {
    return keyword ? `/search/${keyword}/page/${page}` : `/page/${page}`;
  } else {
    if (status === 'Listed')
      return keyword ? `/search/${keyword}/getStatus/${status}/page/${page}` : `/getStatus/${status}/page/${page}`;
    else if (status === 'IdleProduct')
      return keyword ? `/idleProducts/${keyword}/page/${page}` : `/idleProducts/page/${page}`;
    else if (status === 'CheapItem')
      return keyword ? `/search/${keyword}/getCheapItemStatus/${status}/page/${page}` : `/getCheapItemStatus/${status}/page/${page}`;
  
    else
      return keyword ? `/search/${keyword}/getSoldStatus/${status}/page/${page}` : `/getSoldStatus/${status}/page/${page}`;
  }
};

const Paginate = ({ pages, page, keyword = '', origin = 'productList', status }) => {
  console.log('pages in side pagination', pages);

  const maxDisplayedPages = 10;

  if (pages <= 1) {
    return null;
  }

  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, page - Math.floor(maxDisplayedPages / 2));
    const endPage = Math.min(pages, startPage + maxDisplayedPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const renderPageNumbers = () => {
    return getPageNumbers().map((pageNumber) => (
      <LinkContainer
        key={pageNumber}
        to={generateLinkPath(origin, keyword, pageNumber, status)}
      >
        <Pagination.Item active={pageNumber === page}>{pageNumber}</Pagination.Item>
      </LinkContainer>
    ));
  };

  return (
    <Pagination>
      {page > 1 && (
        <LinkContainer to={generateLinkPath(origin, keyword, page - 1, status)}>
          <Pagination.Prev />
        </LinkContainer>
      )}

      {renderPageNumbers()}

      {page < pages && (
        <LinkContainer to={generateLinkPath(origin, keyword, page + 1, status)}>
          <Pagination.Next />
        </LinkContainer>
      )}

    </Pagination>
  );
};

export default Paginate;
