// DimensionInputs.js
import React from 'react';
import { Form } from 'react-bootstrap';

const DimensionInputs = ({ length, width, height, onLengthChange, onWidthChange, onHeightChange ,canEditField}) => {
  return (
    <Form.Group>
      <div className="row">
        <div className="col">
          <Form.Label>L</Form.Label>
          <Form.Control
            type='number'
            value={length}
            onChange={(e) => onLengthChange(e.target.value)}
            readOnly={!canEditField}
          />
        </div>
        <div className="col">
          <Form.Label>W</Form.Label>
          <Form.Control
            type='number'
            required
            value={width}
            onChange={(e) => onWidthChange(e.target.value)}
            readOnly={!canEditField}
          />
        </div>
        <div className="col">
          <Form.Label>H</Form.Label>
          <Form.Control
            type='number'
            value={height}
            onChange={(e) => onHeightChange(e.target.value)}
            required
            readOnly={!canEditField}
          />
        </div>
      </div>
    </Form.Group>
  );
};

export default DimensionInputs;
