import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaTrash, FaThumbsUp } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Message from '../components/Message';

import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import SearchBox from '../components/SearchBox';
import { convertToTimeZone } from '../utils/dateToTimeZone'
import React, { useEffect } from 'react';

import {
  useGetProductsQuery,
  useCreateProductMutation,
} from '../slices/productsApiSlice';
import { toast } from 'react-toastify';
import { handleAuthenticationError } from '../utils/authUtils';
import { useProductDeletion, useAssignToMe } from '../utils/productUtils';
import { TIME_ZONE } from '../constants';


const ProductListScreen = () => {


  const { pageNumber, keyword } = useParams(); // set params from url

  const { userInfo } = useSelector((state) => state.auth);

  const { data, isLoading, error, refetch } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { deleteHandler, loadingDelete } = useProductDeletion();
  const handleDelete = (id) => {
    deleteHandler(id, refetch, (error) => toast.error(error));
  };


  const { assignToMeHandler, assignToMeLoading } = useAssignToMe();
  const handleAsignToMe = (id) => {
    assignToMeHandler(id, refetch, (error) => toast.error(error));
  };



  const [createProduct, { isLoading: loadingCreate }] =
    useCreateProductMutation();

  const createProductHandler = async () => {

    try {
      await createProduct();
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }

  };

  useEffect(() => {
    // Handle errors, check for 401 status code, and redirect to login page
    handleAuthenticationError({ error, dispatch, navigate });
  }, [error, dispatch, navigate]);



  return (
    <>
      {keyword && (
        <Link to='/productList' className='btn btn-light mb-4'>
          Go Back
        </Link>
      )}
      <Row className='align-items-center'>
        <Col>
          <h1>Products</h1>
        </Col>

        { userInfo?.role?.permissions?.includes("can_create_product") && (<Col className='text-end'>
          <LinkContainer to={`/product/create`}>
            <Button className='my-3' onClick={createProductHandler}>
              Scan Product

            </Button>
          </LinkContainer>
        </Col>)}
      </Row>
      {userInfo?.role?.permissions?.includes("can_view_search_box") && ( <Row className='btn  mb-4'>
        <SearchBox from='list' />
      </Row>)}
      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {assignToMeLoading && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th nowrap='true'>Part Number</th>

                <th>Unique No</th>
                <th>Location(Row-Bay-Bin-Section)</th>
                <th>Status</th>
                <th>Owner</th>
                <th>Last Modifier</th>
                <th>Modification Date</th>
                <th>Creation Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.products.map((product, index) => (
                <tr key={product._id}  >
                  <td > {product.partNumber}</td>
                  <td>{product.uniqueNumber}</td>

                  <td>{product.row} -{product.bay}-{product.bin}{product.section ? '-' + product.section : ""}</td>
                  <td nowrap='true' style={{ backgroundColor: product.status === 'InProgress' ? 'orange' : 'inherit' }}>{product.status === 'UnlistedWProblems' ? 'Unlisted W/ Problems' : product.status === 'ListedWProblems' ? 'Listed W/ Problems' : product.status}</td>
                  <td>{product.user.name}</td>
                  <td>{product.modifier && product.modifier.name ? product.modifier.name : product.user.name}</td>
                  <td>{convertToTimeZone(product.updatedAt, TIME_ZONE)}</td>
                  <td>{convertToTimeZone(product.createdAt, TIME_ZONE)}</td>
                  <td>
                    {index === 0 && data.page === 1 &&  userInfo?.role?.permissions?.includes("can_select_only_first_item") && userInfo?.role?.permissions?.includes("can_update_product") && ( // Check if it's the first record and lister
                      <LinkContainer to={`/product/${product._id}/edit/productlist`}>
                        <Button variant='light' className='btn-sm mx-2'>
                          <FaEdit />
                        </Button>
                      </LinkContainer>


                    )}
                    {index === 0 && data.page === 1  && userInfo?.role?.permissions?.includes("can_assign_product_to_me") &&  userInfo?.role?.permissions?.includes("can_select_only_first_item") && (
                      <Button className='btn-sm mx-2' onClick={() => handleAsignToMe(product._id)} >
                        <FaThumbsUp style={{ color: 'white' }} />
                      </Button>)}

                    {!userInfo?.role?.permissions?.includes("can_select_only_first_item") &&  (userInfo?.role?.permissions?.includes("can_update_product")|| product.status === 'InProgress') && ( // Check if it's the first record and lister
                      <LinkContainer to={`/product/${product._id}/edit/productlist`}>
                        <Button variant='light' className='btn-sm mx-2'>
                          <FaEdit />
                        </Button>
                      </LinkContainer>


                    )}
                    {  ( !userInfo?.role?.permissions?.includes("can_select_only_first_item")) && userInfo?.role?.permissions?.includes("can_assign_product_to_me") &&(<Button className='btn-sm mx-2' onClick={() => handleAsignToMe(product._id)} >
                      <FaThumbsUp style={{ color: 'white' }} />
                    </Button>)}

                    {(userInfo?.role?.permissions?.includes("can_delete_product") ) ?
                      <>

                        <Button
                          variant='danger'
                          className='btn-sm mx-2'
                          onClick={() => handleDelete(product._id)}
                        >
                          <FaTrash style={{ color: 'white' }} />
                        </Button></>


                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} keyword={keyword ? keyword : ''} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;