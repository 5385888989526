import React from 'react';
import { useZxing } from "react-zxing";




const BarcodeScanner = ({ onBarcodeScan }) => {
    const { ref } = useZxing({
        onDecodeResult(result) {
            const scannedText = result.getText();
            alert(result.getText());
            onBarcodeScan(scannedText); // Callback to parent component
            // Additional logic if needed
        },
    });
    return (

        <video ref={ref} />
    )
};
export default BarcodeScanner;