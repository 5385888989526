// ImageUpload.js
import React from 'react';
import { Form, Image, Container, Row, Col } from 'react-bootstrap';
import Loader from './Loader'; // Import Loader component
import { toast } from 'react-toastify';

import {  useUploadProductImageMutation} from '../slices/productsApiSlice';


const PublicImageUpload = ({ publicImages,
  setPublicImages,
  selectedImages,
  userInfo

}) => {

  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();

  const uploadFileHandler = async (target) => {
    const formData = new FormData();
    formData.append("from", 'public');
    for (const image of target.files) {
      formData.append("images", image);
    }

    try {
      const res = await uploadProductImage(formData).unwrap();

      toast.success(res.message);
     
      setPublicImages(res.images);

    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleImageClick = async (url) => {
  
    await downloadImage(url, 0);
  };



  const downloadImage = (image, index) => {
    return new Promise((resolve, reject) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = 'image_' + (index + 1) + '.jpg';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Assuming the download link triggers synchronously, resolve the promise
      resolve();
    });
  };

  return (
    <Form.Group controlId='image'>
      <Form.Label>Public Image</Form.Label>
      <Form.Control
        type='text'
        placeholder='Enter image url'
        value={publicImages}
        onChange={(e) => setPublicImages(e.target.value)}

        readOnly={!userInfo?.role?.permissions?.includes("can_upload_public_photo")}
      />
      <Form.Control
        label='Choose File'
        onChange={(e) => uploadFileHandler(e.target)} 
        type='file'
        multiple
        disabled={!userInfo?.role?.permissions?.includes("can_upload_public_photo")}
      />
      <Container>
        <Row>
          {publicImages?.length > 0 &&
            publicImages.map((url, index) => (
              <Col key={index} xs={6} md={4}>
                <Image
                  className="mh-100"
                  src={url}
                  alt={`Image ${index}`}
                  thumbnail
                  onClick={() => handleImageClick(url)}
                  style={{
                    border: selectedImages.includes(url) ? '2px solid blue' : '2px solid transparent',
                    cursor: 'pointer',
                  }}
                />
                <br />
              </Col>
            ))}
        </Row>
      </Container>
      {loadingUpload && <Loader />}
    </Form.Group>
  );
};



export default PublicImageUpload;
