import React from 'react';
import Papa from 'papaparse';

const CSVParser = ({ onParseComplete }) => {

  const handleChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      encoding: 'UTF-8',
      complete: (results) => {

        let itemTitleIndex = results.data[0].findIndex(
          (header) => header === 'Custom Label');

        if (itemTitleIndex === -1) {// in case first row isempty
          itemTitleIndex = results.data[1].findIndex(
            (header) => header === 'Custom Label');

        }
        if (itemTitleIndex > -1) {
          const itemTitleData = results.data.slice(1) // Remove header row
            .map((row) => {
              let itemTitle = row[itemTitleIndex]?.trim() || ''; // Use optional chaining and default to ''
              if (itemTitle !== '' && itemTitle.length < 6) {
                itemTitle = itemTitle.padStart(6, '0'); // Add leading zeros until length is 6
              }
              return itemTitle;
            })
            .filter((itemTitle) => itemTitle !== ''); // Filter empty strings
          handleParseComplete(itemTitleData);
        } else {
          console.error('Column "item title" not found');
          // Handle case where column is not found (optional)
        }

      },
    });
  };
  const handleParseComplete = (data) => {
    if (onParseComplete) {
      onParseComplete(data);
    }
    return data; // Return the parsed data
  };


  return (
    <div>

      <input type="file" accept=".csv" onChange={handleChange} />

      <div style={{ marginTop: '20px' }}> </div>
    </div>
  );
};

export default CSVParser; 