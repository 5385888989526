// axiosInstance.js
import axios from 'axios';

const instance = axios.create();

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // If a 401 response is received, redirect to the login page
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
