
const splitNumbers = (str) => {
    const numbersArray = str.split('-').map((item) => {
        // Check if the item is a number or '*'
        return item === '*' ? '*' : Number(item);
    });

    return numbersArray;
};



export default splitNumbers;
