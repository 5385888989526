// ImageUpload.js
import React from 'react';
import { Form, Image, Container, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader'; // Import Loader component
import { toast } from 'react-toastify';
import { useCallback } from 'react';

import {

  useUploadProductImageMutation,
  useDisplayProductImageMutation

} from '../slices/productsApiSlice';






const ImageUpload = ({ images,
  setImages,
  imageUrls,
  selectedImages,
  setImageUrls,
  userInfo }) => {

  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();


  const uploadFileHandler = async (target) => {
    const formData = new FormData();
    formData.append("from",'private');
    for (const image of target.files) {
      formData.append("images", image);
    }

    try {
      const res = await uploadProductImage(formData).unwrap();

      toast.success(res.message);
     
      setImages(res.images);
      displayFileHandler(res.images)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const handleImageClick = async (url) => {
    // Toggle selection on image click
    /*   const isSelected = selectedImages.includes(url);
      if (isSelected) {
        setSelectedImages(selectedImages.filter((selected) => selected !== url));
      } else {
        setSelectedImages([...selectedImages, url]);
      } */
    await downloadImage(url, 0);
  };

  const [displayProductImages] = useDisplayProductImageMutation();

  const displayFileHandler = useCallback(async (e) => {

    try {

      const res = await displayProductImages(e)
      // .then(({data}) => {
      //   console.log(data)
      //   setImages(data.presignedUrls)
      // });

      setImageUrls(res.data.presignedUrls);
      return res.presignedUrls

    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }, [displayProductImages,setImageUrls]);

  const downloadImage = (image, index) => {
    return new Promise((resolve, reject) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.download = 'image_' + (index + 1) + '.jpg';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Assuming the download link triggers synchronously, resolve the promise
      resolve();
    });
  };

  return (
    <Form.Group controlId='image'>
      <Form.Label>Image</Form.Label>
      <Form.Control
        type='text'
        placeholder='Enter image url'
        value={images}
        onChange={(e) => setImages(e.target.value)}
        required
        readOnly={!userInfo?.role?.permissions?.includes("can_upload_photo")}
      />
      <Form.Control
        label='Choose File'
        onChange={(e) => uploadFileHandler(e.target)} // Pass e.target instead of e
        type='file'
        multiple
        disabled={!userInfo?.role?.permissions?.includes("can_upload_photo")}
      />
      <Container>
        <Row>
          {imageUrls?.length > 0 &&
            imageUrls.map((url, index) => (
              <Col key={index} xs={6} md={4}>
                <Image
                  className="mh-100"
                  src={url}
                  alt={`Image ${index}`}
                  thumbnail
                  onClick={() => handleImageClick(url)}
                  style={{
                    border: selectedImages.includes(url) ? '2px solid blue' : '2px solid transparent',
                    cursor: 'pointer',
                  }}
                />
                <br />
              </Col>
            ))}
        </Row>
      </Container>
      {loadingUpload && <Loader />}
    </Form.Group>
  );
};





export default ImageUpload;
