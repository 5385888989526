import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaTrash, FaThumbsUp } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import SearchBox from '../components/SearchBox';

import { toast } from 'react-toastify';
import {
  useGetProductByStatusQuery,
} from '../slices/productsApiSlice';

import { useProductDeletion, useAssignToMe } from '../utils/productUtils';

import { handleAuthenticationError } from '../utils/authUtils';
import { convertToTimeZone } from '../utils/dateToTimeZone'
import { TIME_ZONE } from '../constants';



const ProductListByStatusScreen = ({ status }) => {

  const { pageNumber, keyword } = useParams(); // set params from url

  const { userInfo } = useSelector((state) => state.auth);
  //const status = 'Listed';

  const { data, isLoading, error, refetch } = useGetProductByStatusQuery({
    pageNumber,
    status,
    keyword
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Handle errors, check for 401 status code, and redirect to login page
    handleAuthenticationError({ error, dispatch, navigate });
  }, [error, dispatch, navigate]);

  const { deleteHandler, loadingDelete } = useProductDeletion();
  const handleDelete = (id) => {
    deleteHandler(id, refetch, (error) => toast.error(error));
  };

  const { assignToMeHandler, assignToMeLoading } = useAssignToMe();
  const handleAsignToMe = (id) => {
    assignToMeHandler(id, refetch, (error) => toast.error(error));

  };



  return (
    <>

      <Row className='align-items-center'>
        <Col>
          <h1>{status} Products</h1>
        </Col>

      </Row>

      <Row className='btn  mb-4'>
        <SearchBox from={status} />
      </Row>
      {loadingDelete && <Loader />}
      {assignToMeLoading && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error.data.message}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th nowrap='true'>Part Number</th>

                <th>Unique No</th>
                <th>Location(Row-Bay-Bin-Section)</th>
                <th>Status</th>
                <th>Owner</th>
                <th>Lister</th>
                <th>Modification Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.products.map((product) => (
                <tr key={product._id}>
                  <td>{product.partNumber}</td>
                  <td>{product.uniqueNumber}</td>

                  <td>{product.row} -{product.bay}-{product.bin} {product.section ? '-'+ product.section:""}</td>
             
                  <td nowrap='true'>{product.status}</td>
                  <td>{product.user.name}</td>

                  <td>{product.lister && product.lister.name ? product.lister.name : 'N/A'}</td>
                  <td>{convertToTimeZone(product.updatedAt, TIME_ZONE)}</td>
                  <td>
                    <LinkContainer to={`/product/${product._id}/edit/${status}Products`}>
                      <Button variant='light' className='btn-sm mx-2'>
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button

                      className='btn-sm mx-2'
                      onClick={() => handleAsignToMe(product._id)}
                    >
                      <FaThumbsUp style={{ color: 'white' }} />
                    </Button>
                    {(userInfo?.role?.permissions?.includes("can_delete_product")) ?
                      <>

                        <Button
                          variant='danger'
                          className='btn-sm mx-2'
                          onClick={() => handleDelete(product._id)}
                        >
                          <FaTrash style={{ color: 'white' }} />
                        </Button></>

                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Paginate pages={data.pages} page={data.page} origin={'searchByStatus'} keyword={keyword ? keyword : ''} status={status} />
        </>
      )}
    </>
  );
};

export default ProductListByStatusScreen;