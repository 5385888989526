import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaTrash, FaEdit} from 'react-icons/fa';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
  useDeleteRoleMutation,
  useGetRolesQuery,
} from '../../slices/rolesApiSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


const RoleListScreen = () => {

  const { data: roles, refetch, isLoading, error } = useGetRolesQuery();

  const [deleteRole] = useDeleteRoleMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure')) {
      try {
       var response = await deleteRole(id);
       if(response?.error){
        toast.error(response?.error?.data?.message );
       }else{
        toast.success('Role deleted succesfully');

       }

        refetch();
       
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>


      <Row className='align-items-center'>
        <Col>
          <h1>Roles</h1>
        </Col>
        <Col className='text-end'>


          <Link to='/admin/role/addRole'>
            Create New Role
          </Link>

        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>

              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th>PERMISSIONS</th>
              <th>STATUS TO PRODUCT LIST</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr key={role._id}>

                <td>{role.name}</td>
                <td>{role.description}</td>
                {(role.permissions && role.permissions.length === 0) &&
                  <td> </td>}
                {(role.permissions && role.permissions.length > 0) && <td>
                  {role.permissions.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index < role.permissions.length - 1 && ', '}
                    </span>
                  ))}
                </td>
                }
                  {(role.productStatusesToDisplay && role.productStatusesToDisplay.length === 0) &&
                  <td> </td>}
                {(role.productStatusesToDisplay && role.productStatusesToDisplay.length > 0) && <td>
                  {role.productStatusesToDisplay.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index < role.productStatusesToDisplay.length - 1 && ', '}
                    </span>
                  ))}
                </td>
                }
               

                <td>

                  <>
                    <LinkContainer
                      to={`/admin/role/${role._id}/edit`}
                      style={{ marginRight: '10px' }}
                    >
                      <Button variant='light' className='btn-sm'>
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(role._id)}
                    >
                      <FaTrash style={{ color: 'white' }} />
                    </Button>
                  </>

                </td>
              </tr>
            ))}
          </tbody>
        </Table >
      )}
    </>
  );
};

export default RoleListScreen;